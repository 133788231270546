export default function Bauliche() {
  const handleEmailClick = () => {
    window.location.href = "mailto:Info@global-baugmbh.de";
  };
  return (
    <>
      <div className=" w-full bg-lightBlue  ">
        <div className="h-[20em] ml-16 mt-20 flex flex-col justify-around max-xl:ml-5 ">
          <h1 className="text-white text-5xl font-bold">
            Bauliche Lösung <span className="font-thin">für alle </span>
          </h1>
          <p className="text-white">
            Wir sind Experten für nachhaltige Bau- und Infrastrukturprojekte.
            Seit 2021 Jahren <br /> realisieren wir innovative Lösungen, die
            höchsten Ansprüchen an Qualität, <br /> Funktionalität und
            Umweltverträglichkeit gerecht werden.
          </p>
          <button
            onClick={handleEmailClick}
            className="p-[1em] bg-darkBlue rounded-lg text-white self-start"
          >
            Kontaktieren
          </button>
        </div>
      </div>
    </>
  );
}
