import Bauliche from "./components/Bauliche";
import Footer from "./components/Footer";
import FourthSection from "./components/FourthSection";
import Home from "./components/Home";
import LastOne from "./components/LastOne";
import Navbar from "./components/Navbar";
import OverHome from "./components/OverHome";
import ProjectsCompleted from "./components/ProjectsCompleted";
import ThirdSection from "./components/ThirdSection";
import UberUns from "./components/UberUns";
import UntersutzeProjekte from "./components/UntersutzeProjekte";

function App() {
  return (
    <div className="">
      <Navbar />
      <Home />
      <OverHome />
      <ProjectsCompleted />
      <UberUns />
      <ThirdSection />
      <FourthSection />
      <UntersutzeProjekte />
      <Bauliche />
      <LastOne />
      <Footer />
    </div>
  );
}

export default App;
