import { motion } from 'framer-motion';

export default function Home() {
  const handleEmailClick = () => {
    window.location.href = "mailto:Info@global-baugmbh.de";
  };

  return (
    <div id="home" className="relative max-w-screen h-[45em] overflow-hidden">
      <motion.img 
        className="w-full h-full object-cover"
        src="./home.png" 
        alt=""
        initial={{ scale: 1.1 }}
        animate={{ scale: 1 }}
        transition={{ duration: 10, ease: "easeOut" }}
      />
      <div className="absolute flex flex-col gap-10 justify-center inset-20 max-xl:w-10/12 max-xl:inset-3">
        <motion.h1 
          className="text-5xl text-white"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Wir sind spezialisiert auf Herstellung von
          <br />
          Bodenplatten und Kellern in Elementbauweise
        </motion.h1>
        <motion.p 
          className="max-w-xl text-white"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
         Wir sind spezialisiert auf Herstellung von Bodenplatten und Kellern in Elementbauweise . Wir mauern auch Ihr Eigenheim von Grund auf , nach Ihren wünschen . 
         Wir stellen Durchbrüche und Kernbohrungen jeder größe her. Sämtliche Abbrucharbeiten werden von uns auch durchgeführt.
        </motion.p>
        <motion.button
          onClick={handleEmailClick}
          className="self-start text-white p-3 bg-darkBlue rounded-lg hover:bg-opacity-90 transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          Kontaktieren
        </motion.button>
      </div>
    </div>
  );
}