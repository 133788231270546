export default function UberUns() {
  const handleEmailClick = () => {
    window.location.href = "mailto:Info@global-baugmbh.de";
  };
  return (
    <div
      id="uber-uns"
      className=" ml-10 justify-center flex py-28 px-10 max-xl:flex-col max-xl:gap-10 max-sm:px-4 max-xl:ml-2 max-xl:py-20"
    >
      <div className="max-w-5xl flex flex-col gap-7">
        <div>
          <h1 className="text-7xl max-md:text-5xl text-darkBlue">
          Wir bauen Gebäude Neu und Um.
          </h1>
        </div>
        <div className="max-w-xl">
          <p className="text-[#6A6A6A]">
          Wir sind Experten für nachhaltige Bau- und Infrastrukturprojekte.
Seit 2021 realisieren wir innovative Lösungen, die
höchsten Ansprüchen an Qualität, Funktionalität und
Umweltverträglichkeit gerecht werden.
          </p>
        </div>
        <div>
          <button
            onClick={handleEmailClick}
            className="p-[1em] bg-blue-950 rounded-lg text-white"
          >
            Kontaktieren
          </button>
        </div>
      </div>
      <div className="basis-1/2 relative">
        <img
          className="object-cover absolute w-[400px] h-[500px] max-[1500px]:w-[15em] max-[1500px]:h-[15em]"
          src="./special4.png"
          alt="secure"
        />
        <img
          className="object-cover absolute w-[400px] h-[500px] top-28 left-28 max-[1500px]:w-[15em] max-[1500px]:h-[15em]"
          src="./6.jpg"
          alt="about"
        />
      </div>
    </div>
  );
}
