export default function OverHome() {
  return (
    <div className="p-3 absolute right-72 top-1/3 w-[30em] h-[34em] bg-white shadow-xl border-t-8 border-indigo-600 max-xl:relative max-xl:right-0 max-xl:w-full">
      <div>
        <h1 className="text-3xl text-blue-900 font-bold divide-y divide-gray-400 mb-10">
        Wir bauen Gebäude  <br />Neu und Um.  <br />
         
        </h1>
      </div>
      <div className="flex flex-col gap-10">
        <div className="flex gap-5 ">
          <img
            className="h-[4rem] w-[4rem] self-center"
            src="./Shape.png"
            alt=""
          />
          <div className="flex flex-col gap-1">
            <h1 className="text-blue-900 text-2xl font-bold">
              Genereller Vertrag
            </h1>
            <p>
              Allgemeine rechtliche <br /> Rahmenbedingungen für
            </p>
          </div>
        </div>
        <div className="flex gap-5 ">
          <img
            className="h-[4rem] w-[4rem] self-center"
            src="./ruler.png"
            alt=""
          />
          <div className="flex flex-col gap-1">
            <h1 className="text-blue-900 text-2xl font-bold">
              Genereller Vertrag
            </h1>
            <p>
              Allgemeine rechtliche <br /> Rahmenbedingungen für
            </p>
          </div>
        </div>
        <div className="flex gap-5 ">
          <img
            className="h-[4rem] w-[4rem] self-center"
            src="./measuring-tool.png"
            alt=""
          />
          <div className="flex flex-col gap-1">
            <h1 className="text-lightBlue text-2xl font-bold">
              Genereller Vertrag
            </h1>
            <p>
              Allgemeine rechtliche <br /> Rahmenbedingungen für
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
