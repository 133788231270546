export default function ThirdSection() {
  const handleEmailClick = () => {
    window.location.href = "mailto:Info@global-baugmbh.de";
  };
  return (
    <div id="dienstleistungen" className="pt-60 pb-10 px-10 max-xl:pt-96 ">
      <div className="flex flex-col text-center gap-5">
        <div>
          <h1 className="text-7xl max-md:text-5xl text-darkBlue">
          Wir bauen Gebäude Neu und Um.</h1>
        </div>
        <p>
          Wir sind Experten für nachhaltige Bau- und Infrastrukturprojekte. 
          Seit  2021 Jahren <br /> realisieren wir innovative Lösungen, die
          höchsten Ansprüchen an Qualität, <br /> Funktionalität und
          Umweltverträglichkeit gerecht werden.
        </p>
        <button
          onClick={handleEmailClick}
          className="p-[1em] w-[8em] bg-blue-950 rounded-lg self-center text-white"
        >
          Kontaktieren
        </button>
      </div>
    </div>
  );
}
