export default function ProjectsCompleted() {
  return (
    <div className="w-1/2 ml-20 mt-3 flex justify-between max-xl:justify-center max-xl:mt-10 ">
      <div>
        <p className="text-darkBlue text-6xl font-medium ">100+</p>
        <p>Abgeschlossene Projekte</p>
      </div>
      <div className="max-xl:hidden">
        <p className="text-darkBlue text-6xl font-medium">10+</p>
        <p>Jahre im Geschäft:</p>
      </div>
      <div className="max-xl:hidden">
        <p className="text-darkBlue text-6xl font-medium">100%</p>
        <p>Pünktliche Fertigstellungen</p>
      </div>
    </div>
  );
}
