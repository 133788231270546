export default function LastOne() {
  const handleEmailClick = () => {
    window.location.href = "mailto:Info@global-baugmbh.de";
  };
  return (
    <div className=" ml-10 justify-center flex py-28 px-10 max-xl:flex-col max-xl:gap-10 max-sm:px-4 max-xl:ml-1">
      <div className="max-w-5xl flex flex-col gap-7 mr-20">
        <div>
          <h1 className="text-7xl max-md:text-5xl text-darkBlue">
          Wir bauen Gebäude Neu und Um
          </h1>
        </div>
        <div className="max-w-xl">
          <p className="text-[#6A6A6A]">
          Ihr verlässlicher Partner für alle Bauprojekte. Mit einem Jahrzehnt Erfahrung und einem Team hochqualifizierter Fachkräfte realisieren wir Ihre Visionen - vom individuellen Eigenheim bis zum komplexen Gewerbeobjekt.
          </p>
        </div>
        <div>
          <button
            onClick={handleEmailClick}
            className="p-[1em] bg-blue-950 rounded-lg text-white"
          >
            Kontaktieren
          </button>
        </div>
      </div>
      <div className="basis-1/2 relative">
        <img className="object-cover " src="./last-one.png" alt="secure" />
      </div>
    </div>
  );
}
