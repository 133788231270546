import { useState } from "react";
import { Link } from "react-scroll";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="max-w-screen flex justify-between items-center sticky top-0 z-10 bg-white">
      <div className="p-4 ml-16  font-bold max-sm:ml-0">
        <Link
          className={`${isOpen ? "hidden" : "block"}`}
          to="home"
          smooth={true}
          duration={500}
        >
          <img className="h-[3em] cursor-pointer" src="./Logo.png" alt="" />
        </Link>
      </div>

      <div
        className="md:hidden p-4 absolute right-0 top-0"
        onClick={toggleMenu}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </div>
      <div className={`${isOpen ? "block" : "hidden"}`}>
        <div className="">
          <ul className="flex flex-col max-xl:mr-32 gap-6 p-4">
            <li className="cursor-pointer ">
              <Link
                className="text-darkBlue"
                to="home"
                smooth={true}
                duration={500}
              >
                Home
              </Link>
            </li>
            <li className="cursor-pointer">
              <Link
                className="text-darkBlue"
                to="uber-uns"
                smooth={true}
                duration={500}
              >
                über uns
              </Link>
            </li>
            <li className="cursor-pointer">
              <Link
                className="text-darkBlue"
                to="dienstleistungen"
                smooth={true}
                duration={500}
              >
                Dienstleistungen
              </Link>
            </li>

            <li className="cursor-pointer">
              <Link
                className="text-darkBlue"
                to="footer"
                smooth={true}
                duration={500}
              >
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
