export default function FourthSection() {
  return (
    <div className="flex text-center w-full justify-evenly max-sm:grid max-sm:gap-10">
      <div className="bg-slate-50 flex flex-col gap-4">
        <img src="./special1.png" alt="" />
        <p>
          Wir konstruieren und verwalten <br /> Gebäude und Infrastrukturen.
        </p>
      </div>
      <div className="bg-slate-50 flex flex-col gap-4">
        <img src="./special2.png" alt="" />
        <p>
          Wir konstruieren und verwalten <br /> Gebäude und Infrastrukturen.
        </p>
      </div>
      <div className="bg-slate-50 flex flex-col gap-4">
        <img src="./special3.png" alt="" />
        <p>
          Wir konstruieren und verwalten <br /> Gebäude und Infrastrukturen.
        </p>
      </div>

      <div className="bg-slate-50  flex flex-col gap-4">
        <img src="./Rectangle9.png" className="h-[20em]" alt="" />
        <p>
          Wir konstruieren und verwalten <br /> Gebäude und Infrastrukturen.
        </p>
      </div>
    </div>
  );
}
