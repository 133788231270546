import React from 'react';

export default function Footer() {
  return (
    <footer id="footer" className="w-full bg-lightBlue text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
 
          <div className="w-full md:w-1/2 mb-6 md:mb-0">
            <div className="flex items-center mb-4">
              <img className="h-12 cursor-pointer mr-4" src="./Logo.png" alt="Logo" />
              <p className="text-sm">Ihr vertrauenswürdiger Partner für Innovation.</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Kontaktieren Sie uns</h3>
              <p>Josefstr.10 51377 Leverkusen</p>
              <p>Tel: 0173-4317404</p>
              <p>E-Mail: Info@global-baugmbh.de</p>
            </div>
          </div>
          

          <div className="w-full md:w-1/2 md:text-right">
            <h3 className="text-lg font-semibold mb-2">Schnellzugriff</h3>
            <nav>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-blue-300">Startseite</a></li>
                <li><a href="#" className="hover:text-blue-300">Über uns</a></li>
                <li><a href="#" className="hover:text-blue-300">Dienstleistungen</a></li>
                <li><a href="#" className="hover:text-blue-300">Kontakt</a></li>
              </ul>
            </nav>
          </div>
        </div>
        
       
        <div className="border-t border-blue-800 mt-8 pt-4 text-center">
          <p>&copy; 2024 Global-Bau GmbH Alle Rechte vorbehalten.</p>
        </div>
      </div>
    </footer>
  );
}