export default function UntersutzeProjekte() {
  return (
    <>
      <div className="text-center mb-8 mt-20">
        <h1 className="text-4xl font-bold text-darkBlue">
          UNTERSTÜTZTE PROJEKTE
        </h1>
      </div>
      <div className="w-full flex justify-center">
        <div className="w-[55em]">
          <div className="grid grid-cols-3 gap-2 ">
            <div className="col-span-1 row-span-2">
              <img
                src="./2.jpg"
                alt="Project 1"
                className="w-full h-full object-cover"
              />
            </div>
            <div>
              <img
                src="./3.jpg"
                alt="Project 2"
                className="w-full h-full object-cover"
              />
            </div>
            <div>
              <img
                src="./4.jpg"
                alt="Project 3"
                className="w-full h-full object-cover"
              />
            </div>
            <div>
              <img
                src="./5.jpg"
                alt="Project 4"
                className="w-full h-full object-cover"
              />
            </div>
            <div>
              <img
                src="./7.jpg"
                alt="Project 4"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
